import React from 'react'
import './follow.css'

export default () => {
    return (
        <div className="follow-page-container">
            <iframe className='chatgpt' title='chatgpt' src="https://chat.soulcuter.cn" ></iframe>
        </div>
    )
}

